import { gql } from '@apollo/client';

export const UPDATE_ACCOUNT = gql`
  mutation UpdateAccount($accountId: ID!, $updateData: UpdateAccountData!) {
    updateAccount(accountId: $accountId, updateData: $updateData) {
      id
      name
      fiatCurrency
      timezone
      sourceListView
      nftListView
      setupRequired
      subtypeFields {
        fundId
        reportingPlatform
        disclaimerAcknowledged
        portfolio
        fundStructure
        fundStructureCurrency
        streamCoreBrokerName
        sftpAdminEmail
        tirAdmin
        tirReportGenerating
      }
      chainalysisIntegrationActive
      chainalysisIntegrationApiKey
      chainalysisIntegrationAuthenticationFailure
    }
  }
`;

export const BULK_ACCOUNTS_UPDATE = gql`
  mutation UpdateBulkAccounts(
    $accountId: ID!
    $accountIds: [ID!]
    $updateObject: BulkAccountUpdateObject!
  ) {
    updateBulkAccounts(
      accountId: $accountId
      accountIds: $accountIds
      updateObject: $updateObject
    ) {
      id
      updating
      timezone
      serialUpdateActive
      taxUpdater {
        algorithmMode
        taxUpdateFailed
        taxUpdateQueued
        taxUpdateInProgress
        taxUpdateNeedsRerun
      }
    }
  }
`;

export const UPDATE_NFT_VIEW = gql`
  mutation UpdateAccount($accountId: ID!, $updateData: UpdateAccountData!) {
    updateAccount(accountId: $accountId, updateData: $updateData) {
      id
      nftListView
    }
  }
`;

export const CHANGE_OWNER = gql`
  mutation ChangeOwner($accountId: ID!, $newOwner: String!) {
    changeOwner(accountId: $accountId, newOwner: $newOwner) {
      id
    }
  }
`;

export const UPDATE_USER_PERMISSION = gql`
  mutation UpdateUserPermission(
    $id: ID!
    $accountId: ID!
    $permission: String!
  ) {
    updatePermission(id: $id, accountId: $accountId, permission: $permission) {
      id
    }
  }
`;

export const DELETE_USER = gql`
  mutation DeleteUser($id: ID!, $accountId: ID!) {
    deleteUserFromAccount(id: $id, accountId: $accountId) {
      userId
      accountId
    }
  }
`;

export const ADD_ACCOUNT_USER = gql`
  mutation AddAccountingUser(
    $email: String!
    $accountId: ID!
    $permission: String!
  ) {
    addUserToAccount(
      email: $email
      accountId: $accountId
      permission: $permission
    ) {
      email
      type
      token
    }
  }
`;

export const CANCEL_INVITE = gql`
  mutation CancelUserInvite($email: String!, $accountId: ID!) {
    cancelInvite(email: $email, accountId: $accountId) {
      email
    }
  }
`;

export const RESEND_INVITE = gql`
  mutation ResendUserInvite($email: String!, $accountId: ID!) {
    resendInvite(email: $email, accountId: $accountId) {
      email
      lastSent
      validTill
      inviteLink
    }
  }
`;

// returns account ID that of account that has been enabled
export const ENABLE_ACCOUNT = gql`
  mutation EnableAccount($accountId: ID!) {
    enableAccount(accountId: $accountId) {
      id
      disabled
    }
  }
`;

export const DELETE_ACCOUNT = gql`
  mutation DeleteAccount($accountId: ID!) {
    deleteAccount(accountId: $accountId) {
      id
    }
  }
`;

export const CREATE_CONTACT = gql`
  mutation CreateContact(
    $accountId: ID!
    $name: String!
    $address: String!
    $code: String
  ) {
    addContactToAccount(
      accountId: $accountId
      name: $name
      address: $address
      code: $code
    ) {
      id
    }
  }
`;

export const BULK_CONTACT_UPLOAD = gql`
  mutation BulkContactUpload($accountId: ID!, $contacts: [ContactData]) {
    bulkContactUpload(accountId: $accountId, contacts: $contacts) {
      code
      success
      message
    }
  }
`;

export const UPDATE_CONTACT = gql`
  mutation UpdateContact(
    $contactId: ID!
    $accountId: ID!
    $name: String
    $address: String
    $code: String
  ) {
    updateContact(
      contactId: $contactId
      accountId: $accountId
      name: $name
      address: $address
      code: $code
    ) {
      id
    }
  }
`;

export const DELETE_CONTACT = gql`
  mutation DeleteContact($contactId: ID!, $accountId: ID!) {
    deleteContact(contactId: $contactId, accountId: $accountId) {
      id
    }
  }
`;

export const CREATE_AUTH = gql`
  mutation CreateIntegration(
    $accountId: ID!
    $appName: String!
    $authUri: String!
  ) {
    createIntegration(
      accountId: $accountId
      appName: $appName
      authUri: $authUri
    ) {
      ... on XeroIntegration {
        id
        authenticationFailed
      }
      ... on QboIntegration {
        id
        authenticationFailed
      }
    }
  }
`;

export const DELETE_CONNECTION = gql`
  mutation DeleteConnection($accountId: ID!, $appName: String!) {
    deleteAuth(accountId: $accountId, appName: $appName) {
      ... on XeroIntegration {
        accountId
      }
      ... on QboIntegration {
        accountId
      }
    }
  }
`;

export const UPDATE_INTEGRATION = gql`
  mutation UpdateIntegration(
    $accountId: ID!
    $integrationUpdateData: IntegrationUpdateData!
    $appName: String!
  ) {
    updateIntegration(
      accountId: $accountId
      integrationUpdateData: $integrationUpdateData
      appName: $appName
    ) {
      ... on QboIntegration {
        id
        minUsdValue
        syncMode
      }
      ... on XeroIntegration {
        id
        minUsdValue
        syncMode
      }
    }
  }
`;

export const SET_DEFAULT_ACCOUNTS = gql`
  mutation SetDefaultAccounts(
    $accountId: ID!
    $appName: String!
    $integrationUpdateData: IntegrationUpdateData!
  ) {
    updateIntegration(
      accountId: $accountId
      appName: $appName
      integrationUpdateData: $integrationUpdateData
    ) {
      ... on XeroIntegration {
        id
        accountId
        defaultAssetAccount
        defaultIncomeAccount
        defaultExpenseAccount
        defaultFeeAccount
        defaultSuspenseAccount
      }
      ... on QboIntegration {
        id
        accountId
        defaultAssetAccount
        defaultIncomeAccount
        defaultExpenseAccount
        defaultFeeAccount
        defaultSuspenseAccount
      }
    }
  }
`;

export const MAP_EXCHANGE = gql`
  mutation MapExchangeToIntegration(
    $accountId: ID!
    $eSourceId: ID!
    $exchangeMapping: ExchangeMapData!
    $appName: String!
  ) {
    mapExchangeToIntegration(
      accountId: $accountId
      eSourceId: $eSourceId
      exchangeMapping: $exchangeMapping
      appName: $appName
    ) {
      id
      accountId
      feeAccount
      exchangeAuthId
      currencyMappings {
        currency
        inboundAccount
        outboundAccount
        assetAccount
      }
    }
  }
`;

export const MAP_EXCHANGE_CURRENCIES = gql`
  mutation MapExchangeCurrencies(
    $accountId: ID!
    $eSourceId: ID!
    $appName: String!
    $currencyMappings: [ExchangeCurrencyMapData]!
  ) {
    mapExchangeCurrency(
      accountId: $accountId
      eSourceId: $eSourceId
      appName: $appName
      currencyMappings: $currencyMappings
    ) {
      id
      accountId
      exchangeAuthId
      type
      feeAccount
      currencyMappings {
        currency
        inboundAccount
        outboundAccount
        assetAccount
      }
    }
  }
`;

export const UPDATE_ORGANIZATION = gql`
  mutation UpdateOrganization(
    $id: ID!
    $accountId: ID!
    $name: String!
    $address: String!
  ) {
    updateOrganization(
      id: $id
      accountId: $accountId
      name: $name
      address: $address
    ) {
      id
      accountId
      name
      address
    }
  }
`;

export const ADD_ORGANIZATION = gql`
  mutation AddOrganization($accountId: ID!, $name: String!, $address: String!) {
    addOrganization(accountId: $accountId, name: $name, address: $address) {
      id
      accountId
      name
      address
    }
  }
`;

export const SET_TX_ORGANIZATION = gql`
  mutation SetTxOrganization(
    $accountId: ID!
    $transactionId: String!
    $organizationId: ID
  ) {
    setTxOrganization(
      accountId: $accountId
      transactionId: $transactionId
      organizationId: $organizationId
    ) {
      id
      customData {
        organizationId
      }
    }
  }
`;

export const REMOVE_ORGANIZATION = gql`
  mutation RemoveOrganization($id: ID!, $accountId: ID!) {
    removeOrganization(id: $id, accountId: $accountId) {
      id
      name
      address
      accountId
    }
  }
`;

export const SYNC_INTEGRATION = gql`
  mutation SyncNow($accountId: ID!, $appName: String!) {
    syncNow(accountId: $accountId, appName: $appName) {
      ... on XeroIntegration {
        accountId
      }
      ... on QboIntegration {
        accountId
      }
    }
  }
`;

export const MAP_WALLET = gql`
  mutation MapWalletToIntegration(
    $accountId: ID!
    $walletMapping: WalletMapData!
    $appName: String!
  ) {
    mapWalletToIntegration(
      accountId: $accountId
      walletMapping: $walletMapping
      appName: $appName
    ) {
      id
      accountId
      walletId
      type
      cryptoMappings {
        crypto
        inboundAccount
        outboundAccount
        assetAccount
      }
      assetAccount
      inboundAccount
      outboundAccount
      feeAccount
    }
  }
`;

export const MAP_WALLET_CRYPTOS = gql`
  mutation MapWalletCryptos(
    $accountId: ID!
    $walletId: String!
    $appName: String!
    $cryptoMappings: [CurrencyMapData]!
  ) {
    mapWalletCurrency(
      accountId: $accountId
      walletId: $walletId
      appName: $appName
      cryptoMappings: $cryptoMappings
    ) {
      crypto
      inboundAccount
      outboundAccount
      assetAccount
    }
  }
`;

export const UPDATE_TXTABLE_SETTINGS = gql`
  mutation UpdateTXTableSettings(
    $accountId: ID!
    $settings: TxTableSettingsInput!
  ) {
    updateTableSettings(accountId: $accountId, settings: $settings) {
      id
      txTableSettings {
        showZeroValue
        showDust
        defaultTableView
      }
    }
  }
`;

export const DELETE_FILTER = gql`
  mutation DeleteSavedFilter($accountId: ID!, $id: ID!) {
    deleteSavedFilter(accountId: $accountId, id: $id) {
      id
      accountId
    }
  }
`;

export const ADD_FILTER = gql`
  mutation addSavedFilter(
    $accountId: ID!
    $filter: TxQueryInput!
    $filterName: String!
  ) {
    addSavedFilter(
      accountId: $accountId
      filter: $filter
      filterName: $filterName
    ) {
      id
      accountId
      filterName
      filter {
        txDateStatic
        txDateStart
        txDateEnd
        exchanges
        wallets
        currencies
        types
        contacts
        contracts
      }
    }
  }
`;

export const UPDATE_ESOURCE_DATA = gql`
  mutation UpdateESourceData($accountId: ID!, $eSourceId: ID!) {
    updateESourceData(accountId: $accountId, eSourceId: $eSourceId) {
      id
      updating
    }
  }
`;

export const UPDATE_EXCHANGE = gql`
  mutation UpdateExchange(
    $accountId: ID!
    $eSourceId: ID!
    $eSourceData: ESourceUpdateData!
  ) {
    updateESource(
      accountId: $accountId
      eSourceId: $eSourceId
      eSourceData: $eSourceData
    ) {
      id
      nickname
    }
  }
`;

export const EXCHANGE_OAUTH_UPDATE = gql`
  mutation UpdateExchangeOAuthAccessToken(
    $accountId: ID!
    $exchangeId: ID!
    $code: String!
    $appAlias: String
  ) {
    updateExchangeOAuthToken(
      accountId: $accountId
      exchangeId: $exchangeId
      code: $code
      appAlias: $appAlias
    ) {
      id
    }
  }
`;

export const ADD_EXCHANGE = gql`
  mutation AddExchange(
    $accountId: ID!
    $eSourceData: ESourceUpdateData!
    $appAlias: String
  ) {
    addESource(
      accountId: $accountId
      eSourceData: $eSourceData
      appAlias: $appAlias
    ) {
      id
      accountId
      exchangeName
      type
    }
  }
`;

export const VALIDATE_ESOURCE_AUTH = gql`
  mutation ValidateESourceAuth($eSourceData: ESourceUpdateData!) {
    validateESourceAuth(eSourceData: $eSourceData)
  }
`;

export const DELETE_EXCHANGE = gql`
  mutation DeleteExchange($accountId: ID!, $eSourceId: ID!) {
    deleteESource(accountId: $accountId, eSourceId: $eSourceId) {
      id
    }
  }
`;

export const UPDATE_EXCHANGE_AUTH = gql`
  mutation UpdateExchangeAuth(
    $accountId: ID!
    $eSourceId: ID!
    $eSourceData: ESourceUpdateData!
  ) {
    updateExchangeAuth(
      accountId: $accountId
      eSourceId: $eSourceId
      eSourceData: $eSourceData
    ) {
      id
    }
  }
`;

export const UPDATE_EXCHANGE_BALANCE = gql`
  mutation UpdateManualExchangeBalance($accountId: ID!, $data: BalanceInput) {
    upsertManualExchangeBalances(accountId: $accountId, data: $data) {
      id
      balances {
        amount
        currency
      }
      exchangeId
    }
  }
`;

export const CREATE_MANUAL_ORDER = gql`
  mutation CreateManualOrder(
    $accountId: ID!
    $exchangeId: String!
    $data: [ManualExchangeEntry]
    $batchDetails: BatchImportDetails
  ) {
    createManualExchangeOrder(
      accountId: $accountId
      exchangeId: $exchangeId
      data: $data
      batchDetails: $batchDetails
    )
  }
`;

export const CREATE_MANUAL_TRANSACTION = gql`
  mutation CreateManualTransaction(
    $accountId: ID!
    $walletId: String!
    $data: ManualWalletEntry
    $batchDetails: BatchImportDetails
  ) {
    createManualWalletTransaction(
      accountId: $accountId
      walletId: $walletId
      data: $data
      batchDetails: $batchDetails
    )
  }
`;

export const DELETE_MANUAL_ORDER = gql`
  mutation DeleteManualOrder(
    $accountId: ID!
    $exchangeId: String!
    $entries: [ManualEntry]
  ) {
    deleteManualExchangeOrder(
      accountId: $accountId
      exchangeId: $exchangeId
      entries: $entries
    )
  }
`;

export const DELETE_MANUAL_TRANSACTION = gql`
  mutation DeleteManualTransaction(
    $accountId: ID!
    $walletId: String!
    $entries: [ManualEntry]
  ) {
    deleteManualWalletTransaction(
      accountId: $accountId
      walletId: $walletId
      entries: $entries
    )
  }
`;

export const EDIT_MANUAL_ORDER = gql`
  mutation EditManualOrder(
    $accountId: ID!
    $exchangeId: String!
    $data: [ManualExchangeEntry]
  ) {
    updateManualExchangeOrder(
      accountId: $accountId
      exchangeId: $exchangeId
      data: $data
    )
  }
`;

export const EDIT_MANUAL_TRANSACTION = gql`
  mutation EditManualTransaction(
    $accountId: ID!
    $walletId: String!
    $data: ManualWalletEntry
  ) {
    updateManualWalletTransaction(
      accountId: $accountId
      walletId: $walletId
      data: $data
    )
  }
`;

export const CREATE_TAX_PAYER_ACCOUNT = gql`
  mutation CreateTaxPayerAccount(
    $name: String!
    $timezone: String
    $taxPayerDetails: TaxPayerDetailsInput
    $accountSubtype: String
  ) {
    createTaxPayerAccount(
      name: $name
      timezone: $timezone
      taxPayerDetails: $taxPayerDetails
      accountSubtype: $accountSubtype
    ) {
      id
    }
  }
`;

export const CREATE_ACCOUNTING_ACCOUNT = gql`
  mutation CreateAccountingAccount(
    $name: String!
    $hostedPageId: String
    $achCustomer: Boolean
    $timezone: String
    $accountingAdd: Boolean
    $subtype: String
    $subtypeFields: AccountingSubtypeInput
  ) {
    createAccountingAccount(
      name: $name
      hostedPageId: $hostedPageId
      achCustomer: $achCustomer
      timezone: $timezone
      accountingAdd: $accountingAdd
      subtype: $subtype
      subtypeFields: $subtypeFields
    ) {
      id
      name
    }
  }
`;

export const UPDATE_LEDGIBLE_USER = gql`
  mutation UpdateLedgibleUser($auth0User: AuthZeroUser!) {
    updateLedgibleUser(auth0User: $auth0User) {
      id
      profile {
        email_verified
        email
        username
        picture
        user_metadata {
          use_mfa
          verification_email_sent
          us_bank_portfolio_viewer
          ledgible_tmp_user_token
        }
        created_at
        last_password_reset
        updated_at
        org_id
      }
      active
      addAccount
      achEnabled
      allowedApplications
      accountPermissions {
        userId
        accountId
        role
        isOwner
        type
      }
      redirectToClient
    }
  }
`;

export const CREATE_USER = gql`
  mutation CreateUser(
    $auth0User: AuthZeroUser!
    $token: String
    $enableInitialAccountingAccountCreation: Boolean
  ) {
    createUser(
      auth0User: $auth0User
      token: $token
      enableInitialAccountingAccountCreation: $enableInitialAccountingAccountCreation
    ) {
      id
      profile {
        email_verified
        email
        username
        picture
        user_metadata {
          use_mfa
          verification_email_sent
          us_bank_portfolio_viewer
          ledgible_tmp_user_token
        }
        created_at
        last_password_reset
        updated_at
        org_id
      }
      active
      addAccount
      achEnabled
      allowedApplications
      accountPermissions {
        userId
        accountId
        role
        isOwner
        type
      }
    }
  }
`;

export const CUSTOMER_PORTAL_SESSION = gql`
  mutation GetCustomerPortal {
    generateCustomerPortal {
      id
      token
      access_url
      status
      created_at
      expires_at
      object
      customer_id
      redirect_url
      linked_customers {
        object
        customer_id
        email
        has_billing_address
        has_payment_method
        has_active_subscription
      }
    }
  }
`;

export const CUSTOMER_CHECKOUT_SESSION = gql`
  mutation GetCheckoutSession($planId: String!, $disableTrial: Boolean) {
    generateCheckoutPortal(planId: $planId, disableTrial: $disableTrial) {
      id
      type
      url
      state
      embed
      created_at
      expires_at
    }
  }
`;

export const CUSTOMER_ONE_TIME_CHECKOUT = gql`
  mutation GetAddonCheckoutSession($accountId: ID!, $addOnId: String!) {
    generateCheckoutAddonPortal(accountId: $accountId, addOnId: $addOnId) {
      id
      type
      url
      state
      embed
      created_at
      expires_at
    }
  }
`;

export const CUSTOMER_UPGRADE_SESSION = gql`
  mutation GetUpgradeSession($planId: String!, $accountId: ID!) {
    generateUpgradePortal(planId: $planId, accountId: $accountId) {
      id
      type
      url
      state
      embed
      created_at
      expires_at
    }
  }
`;

export const SET_FIRST_LOGIN = gql`
  mutation SetFirstLogin {
    setFirstLogin {
      userId
      firstLogin
    }
  }
`;

export const CANCEL_REPORT = gql`
  mutation CancelReport($id: ID!, $accountId: ID!) {
    cancelReport(id: $id, accountId: $accountId) {
      id
      status
      finished
    }
  }
`;

export const DELETE_REPORTS = gql`
  mutation DeleteReports($ids: [ID!], $accountId: ID!) {
    deleteReports(ids: $ids, accountId: $accountId)
  }
`;

export const ADD_CHAINALYSIS_INTEGRATION_API = gql`
  mutation AddChainalysisIntegrationKey($accountId: ID!, $apiKey: String!) {
    addChainalysisIntegrationKey(accountId: $accountId, apiKey: $apiKey) {
      id
      chainalysisIntegrationActive
      chainalysisIntegrationApiKey
      chainalysisIntegrationAuthenticationFailure
    }
  }
`;

export const CREATE_MEMO = gql`
  mutation AddMemo(
    $accountId: ID!
    $transaction: TransactionAndSourceInput!
    $appType: String!
    $memo: String!
  ) {
    createMemo(
      accountId: $accountId
      transaction: $transaction
      appType: $appType
      memo: $memo
    ) {
      memo
      id
      transactionId
      createdBy
      createdByEmail
      createdAt
    }
  }
`;

export const UPDATE_MEMO = gql`
  mutation UpdateMemo($accountId: ID!, $memoId: ID!, $memo: String!) {
    updateMemo(accountId: $accountId, id: $memoId, memo: $memo) {
      memo
      id
      transactionId
      createdBy
      createdByEmail
      createdAt
    }
  }
`;

export const DELETE_MEMO = gql`
  mutation DeleteMemo($accountId: ID!, $id: ID!) {
    deleteMemo(accountId: $accountId, id: $id)
  }
`;

export const CREATE_BULK_MEMOS = gql`
  mutation CreateBulkMemos(
    $accountId: ID!
    $transactions: [TransactionAndSourceInput]!
    $appType: String!
    $memo: String!
  ) {
    createMemosByTransactionIds(
      accountId: $accountId
      transactions: $transactions
      appType: $appType
      memo: $memo
    ) {
      data {
        accountId
        createdBy
        createdByEmail
        createdAt
        id
        transactionId
        transactionSourceId
        memo
      }
      errors {
        appType
        code
        message
        transactionId
      }
    }
  }
`;

export const VALIDATE_BULK_MEMOS = gql`
  mutation ValidateBulkMemos(
    $accountId: ID!
    $transactions: [TransactionAndSourceInput]!
    $appType: String!
    $memo: String!
  ) {
    validateMemosByTransactionIds(
      accountId: $accountId
      transactions: $transactions
      appType: $appType
      memo: $memo
    ) {
      appType
      code
      message
      transactionId
    }
  }
`;

export const DELETE_BULK_MEMOS = gql`
  mutation DeleteBulkMemos(
    $accountId: ID!
    $transactions: [TransactionAndSourceInput]!
  ) {
    deleteMemosByTransactionIds(
      accountId: $accountId
      transactions: $transactions
    ) {
      transactionId
      transactionSourceId
    }
  }
`;

export const UPDATE_ALL_SOURCES = gql`
  mutation UpdateAllSources($accountId: ID!) {
    updateAllSources(accountId: $accountId) {
      id
      updating
    }
  }
`;

export const UPDATE_TAX_DATA = gql`
  mutation UpdateTaxData($accountId: ID!, $noDataSources: Boolean) {
    triggerTaxUpdate(accountId: $accountId, noDataSources: $noDataSources) {
      id
      updating
      taxUpdater {
        taxUpdateFailed
        taxUpdateQueued
        taxUpdateInProgress
        taxUpdateNeedsRerun
      }
    }
  }
`;

export const BULK_TAX_UPDATE = gql`
  mutation TriggerBulkTaxUpdate($accountId: ID!, $accountIds: [ID!]) {
    triggerBulkTaxUpdate(accountId: $accountId, accountIds: $accountIds) {
      id
      updating
      taxUpdater {
        taxUpdateFailed
        taxUpdateQueued
        taxUpdateInProgress
        taxUpdateNeedsRerun
        specIdFeature
      }
    }
  }
`;

export const RESET_PASSWORD = gql`
  mutation ChangePassword {
    changePassword {
      id
      profile {
        email_verified
        email
        picture
        user_metadata {
          use_mfa
          verification_email_sent
          us_bank_portfolio_viewer
        }
        created_at
        last_password_reset
      }
      active
      addAccount
      achEnabled
    }
  }
`;

export const UPDATE_PW_PRE_USER = gql`
  mutation UpdateUserPassword(
    $sessionToken: String!
    $state: String!
    $newPassword: String!
  ) {
    updatePasswordBeforeUserCreation(
      sessionToken: $sessionToken
      state: $state
      newPassword: $newPassword
    ) {
      updatedToken
      continueUrl
    }
  }
`;

export const ADD_ADDRESS = gql`
  mutation AddAddress($accountId: ID!, $walletId: ID!, $address: String!) {
    addAddress(accountId: $accountId, walletId: $walletId, address: $address) {
      id
    }
  }
`;

export const ADD_WALLET = gql`
  mutation AddWallet($accountId: ID!, $walletData: WalletUpdateData!) {
    addWallet(accountId: $accountId, walletData: $walletData) {
      id
    }
  }
`;

export const ADD_HD_WALLET = gql`
  mutation AddHDWallet($accountId: ID!, $walletData: WalletUpdateData!) {
    addHDWallet(accountId: $accountId, walletData: $walletData) {
      id
    }
  }
`;

export const CREATE_EXTERNAL_WALLET = gql`
  mutation CreateExternalWallet(
    $accountId: ID!
    $credentials: CredentialsInput!
    $name: String!
    $sanityIds: SanityRefInput
    $taxOwnership: String
    $type: String!
  ) {
    createExternalWallet(
      accountId: $accountId
      credentials: $credentials
      name: $name
      sanityIds: $sanityIds
      taxOwnership: $taxOwnership
      type: $type
    ) {
      id
    }
  }
`;

export const UPDATE_WALLET = gql`
  mutation UpdateWallet(
    $accountId: ID!
    $walletId: ID!
    $walletData: WalletUpdateData!
  ) {
    updateWallet(
      accountId: $accountId
      walletId: $walletId
      walletData: $walletData
    ) {
      id
      name
      addresses {
        id
        address
      }
      chainalysisAlertsCount
      chainalysisWalletIntegrationActive
    }
  }
`;

export const DELETE_ADDRESS = gql`
  mutation DeleteAddress($accountId: ID!, $walletId: ID!, $address: String!) {
    deleteAddress(
      accountId: $accountId
      walletId: $walletId
      address: $address
    ) {
      id
    }
  }
`;

export const DELETE_EXTERNAL_WALLET = gql`
  mutation DeleteExternalWallet($accountId: ID!, $walletId: ID!) {
    deleteExternalWallet(accountId: $accountId, walletId: $walletId) {
      id
    }
  }
`;

export const DELETE_WALLET = gql`
  mutation DeleteWallet($accountId: ID!, $walletId: ID!) {
    deleteWallet(accountId: $accountId, walletId: $walletId) {
      id
    }
  }
`;

export const UPDATE_WALLET_CREDENTIALS = gql`
  mutation UpdateWalletCredentials(
    $accountId: ID!
    $walletId: ID!
    $accessKey: String
    $apiKey: String
    $accessToken: String
    $apiSecret: String
    $uri: String
    $apiUser: String
    $username: String
    $password: String
  ) {
    updateExternalWalletCredentials(
      accountId: $accountId
      accessKey: $accessKey
      accessToken: $accessToken
      apiKey: $apiKey
      apiSecret: $apiSecret
      apiUser: $apiUser
      password: $password
      uri: $uri
      username: $username
      walletId: $walletId
    ) {
      id
    }
  }
`;

export const UPDATE_WALLET_DATA = gql`
  mutation UpdateWalletData($accountId: ID!, $walletId: ID!) {
    updateWalletData(accountId: $accountId, walletId: $walletId) {
      id
      updating
      sourceUpdating
    }
  }
`;

export const REQUEST_VERIFICATION = gql`
  mutation RequestVerification(
    $callbackUrl: String
    $substitutionData: EmailSubstitutionData
  ) {
    verifyEmailRequest(
      callbackUrl: $callbackUrl
      substitutionData: $substitutionData
    ) {
      id
      profile {
        email_verified
        email
        picture
        user_metadata {
          use_mfa
          verification_email_sent
          us_bank_portfolio_viewer
        }
        created_at
        last_password_reset
      }
      active
      addAccount
      achEnabled
    }
  }
`;

export const COUPON_CHECK = gql`
  mutation GetCoupon($accountId: ID!, $couponCode: String!) {
    confirmChargeBeeCoupon(accountId: $accountId, couponCode: $couponCode) {
      id
      name
      currency_code
      discount_amount
      discount_type
      discount_percentage
      duration_type
      isInvalid
    }
  }
`;

export const CHECKOUT_CUSTOMER = gql`
  mutation CheckoutCustomer(
    $accountId: ID!
    $clientTotal: Float!
    $couponCode: String
  ) {
    directTaxCheckout(
      accountId: $accountId
      clientTotal: $clientTotal
      couponCode: $couponCode
    ) {
      id
      charged
      invoice {
        id
        customer_id
        status
        price_type
        date
        due_date
        total
        amount_paid
        amount_adjusted
        write_off_amount
        credits_applied
        amount_due
        paid_at
        updated_at
        resource_version
        deleted
        first_invoice
        amount_to_collect
        round_off_amount
        has_advance_charges
        currency_code
        base_currency_code
      }
    }
  }
`;

export const GIVE_CONSENT = gql`
  mutation GiveConsent(
    $accountId: ID!
    $preparerLinkId: ID!
    $consent: Boolean!
  ) {
    updateConsent(
      accountId: $accountId
      preparerLinkId: $preparerLinkId
      consent: $consent
    ) {
      id
      clientConsent
    }
  }
`;

export const SUBMIT_TAX_YEAR = gql`
  mutation SubmitTaxYearToPreparer(
    $accountId: ID!
    $preparerLinkId: ID!
    $taxYear: String!
  ) {
    updateTaxYearSubmitted(
      accountId: $accountId
      preparerLinkId: $preparerLinkId
      taxYear: $taxYear
    ) {
      id
      submittedYears
    }
  }
`;

export const CREATE_TAX_REPORT = gql`
  mutation CreateTaxReport(
    $accountId: ID!
    $taxAccountId: ID
    $type: ReportType!
    $params: CreateReportParams
    $taxYear: String
    $consumer: CreateReportConsumer
  ) {
    createTaxReport(
      accountId: $accountId
      taxAccountId: $taxAccountId
      type: $type
      params: $params
      taxYear: $taxYear
      consumer: $consumer
    ) {
      id
      account
      type
      taxAccountId
      taxYear
      isEmpty
      requested
      finished
      error
      status
      parameters {
        wallet
        exchange
        start
        end
        asset
        name
        filters {
          txDateStatic
        }
        selected {
          txId
        }
        taxCategory
        timezone
      }
      taxDetails {
        accountName
        taxYear
        accountId
        contactName
        contactEmail
        prepTypeFields {
          goSystemLocator
        }
        preparerOnly
      }
    }
  }
`;

export const CREATE_ACCOUNTING_REPORT = gql`
  mutation CreateAccountingReport(
    $accountId: ID!
    $type: ReportType!
    $params: CreateReportParams
    $consumer: CreateReportConsumer
  ) {
    createAccountingReport(
      accountId: $accountId
      type: $type
      params: $params
      consumer: $consumer
    ) {
      id
      account
      type
      taxAccountId
      taxYear
      isEmpty
      requested
      finished
      error
      status
      parameters {
        wallet
        exchange
        start
        end
        asset
        name
        filters {
          txDateStatic
        }
        selected {
          txId
        }
        taxCategory
        timezone
      }
    }
  }
`;

export const ADD_TAX_PRO = gql`
  mutation AddTaxProConnection($accountId: ID!, $linkCode: String!) {
    createPreparerLinkFromCode(accountId: $accountId, linkCode: $linkCode) {
      id
      preparerDisplayName
      billingEntity
    }
  }
`;

export const DELETE_TAX_PRO = gql`
  mutation RemoveTaxProConnection($accountId: ID!, $preparerLinkId: ID!) {
    deletePreparerLink(accountId: $accountId, preparerLinkId: $preparerLinkId) {
      id
    }
  }
`;

export const RUN_RECATEGORIZE_RULE = gql`
  mutation RunRecategorizeRule(
    $accountId: ID!
    $filter: TaxCategorizerFilterInput!
    $category: TaxCategory
    $mode: CategorizerMode!
    $returnOnly: Boolean
  ) {
    runRecategorizeRule(
      accountId: $accountId
      filter: $filter
      category: $category
      mode: $mode
      returnOnly: $returnOnly
    ) {
      id
      category
      ignore
      context {
        assetName
        chain
        collectionName
        contractAddress
        subtype
        tokenId
      }
      userChanges {
        category
      }
      specIdTransactions {
        specId
      }
      costBasisAssignments {
        lotIds
        lotNo
      }
    }
  }
`;

export const SET_TX_CATEGORIES = gql`
  mutation SetTxCategories(
    $accountId: ID!
    $filter: TaxCategorizerFilterInput!
    $category: TaxCategory!
    $mode: CategorizerMode!
    $appLocation: CategoryAssignmentAppLocation
    $customData: TxCategoryCustomData
  ) {
    setTxCategories(
      accountId: $accountId
      filter: $filter
      category: $category
      mode: $mode
      appLocation: $appLocation
      customData: $customData
    ) {
      id
      category
      ignore
      gainLoss
      context {
        assetName
        chain
        collectionName
        contractAddress
        subtype
        tokenId
      }
      costBasisAssignments {
        lotIds
        lotNo
      }
      userControl {
        transferMatching
        category
      }
      userChanges {
        category
      }
      customData {
        organizationId
      }
      transferMatchManual
      transferMatchingDisabled
      reconciliations {
        proceeds
        proceedsDisregardingFees
        isEdited
        isSpecId
      }
      specIdTransactions {
        specId
      }
    }
  }
`;

export const RESET_TX_CATEGORIES = gql`
  mutation ResetTaxCategories(
    $accountId: ID!
    $filter: TaxCategorizerFilterInput!
    $resetAll: Boolean
  ) {
    resetTxCategories(
      accountId: $accountId
      filter: $filter
      resetAll: $resetAll
    ) {
      id
      category
      ignore
      gainLoss
      context {
        assetName
        chain
        collectionName
        contractAddress
        subtype
        tokenId
      }
      costBasisAssignments {
        lotIds
        lotNo
      }
      userControl {
        transferMatching
        category
      }
      userChanges {
        category
      }
      customData {
        organizationId
      }
      transferMatchManual
      transferMatchingDisabled
      reconciliations {
        proceeds
        proceedsDisregardingFees
        isEdited
        isSpecId
      }
    }
  }
`;

export const EDIT_COST_BASIS = gql`
  mutation EditCostBasis(
    $accountId: ID!
    $transactionId: String!
    $sourceId: String!
    $price: Float!
  ) {
    updateCostBasis(
      accountId: $accountId
      transactionId: $transactionId
      sourceId: $sourceId
      price: $price
    )
  }
`;

export const IGNORE_TAX_TX_UPDATE = gql`
  mutation UpdateTxIgnore($accountId: ID!, $taxTxId: ID!, $ignore: Boolean!) {
    updateTxIgnore(accountId: $accountId, taxTxId: $taxTxId, ignore: $ignore)
  }
`;

export const REVERT_COST_BASIS = gql`
  mutation RevertCostBasis(
    $accountId: ID!
    $transactionId: String!
    $sourceId: String!
  ) {
    revertCostBasis(
      accountId: $accountId
      transactionId: $transactionId
      sourceId: $sourceId
    )
  }
`;

export const REVERT_COST_BASIS_ASSIGNMENT = gql`
  mutation RevertCostBasis(
    $accountId: ID!
    $taxTxIds: [String]!
    $operationType: CostBasisRevertOption!
  ) {
    revertCostBasisAssignment(
      accountId: $accountId
      taxTxIds: $taxTxIds
      operationType: $operationType
    ) {
      id
      costBasis
      costBasisDisregardingFees
      proceedsDisregardingFees
      costBasisAssignments {
        lotIds
        lotNo
      }
    }
  }
`;

export const ASSIGN_COST_BASIS = gql`
  mutation AssignCostBasis($accountId: ID!, $taxTxIds: [String]!) {
    assignCostBasis(accountId: $accountId, taxTxIds: $taxTxIds) {
      id
      costBasis
      isEdited
      priceAtTimeOfTx
      amount
      costBasisDisregardingFees
      proceedsDisregardingFees
      costBasisAssignments {
        lotIds
        lotNo
      }
      reconciliations {
        isEdited
        isSpecId
      }
    }
  }
`;

export const UPDATE_TAX_METHOD = gql`
  mutation UpdateTaxCalcMethod($accountId: ID!, $newMethod: TaxMethod!) {
    updateTaxMethod(accountId: $accountId, newMethod: $newMethod) {
      id
      taxPayerDetails {
        taxMethod
      }
      taxUpdater {
        taxUpdateNeedsRerun
      }
    }
  }
`;

export const UPDATE_TAX_ALGORITHM_MODE = gql`
  mutation UpdateTaxAlgorithmMode($accountId: ID!, $mode: TaxAlgorithmMode!) {
    updateTaxAlgorithmMode(accountId: $accountId, mode: $mode) {
      id
      taxUpdater {
        algorithmMode
        taxUpdateNeedsRerun
      }
    }
  }
`;

export const UPDATE_TAX_YEAR = gql`
  mutation UpdateTaxYear($accountId: ID!, $taxYear: String!) {
    updateTaxYear(accountId: $accountId, taxYear: $taxYear) {
      id
      taxPayerDetails {
        taxYear
      }
      taxUpdater {
        taxUpdateNeedsRerun
      }
    }
  }
`;

export const DELETE_PREPARER_LINK = gql`
  mutation DeletePreparerLink($accountId: ID!, $preparerLinkId: ID!) {
    deletePreparerLink(accountId: $accountId, preparerLinkId: $preparerLinkId) {
      id
    }
  }
`;

export const RESEND_CODE_INVITE = gql`
  mutation ResendCodeInvite($preparerLinkId: ID!, $accountId: ID!) {
    updateCodeInvite(preparerLinkId: $preparerLinkId, accountId: $accountId) {
      id
      codeLastSent
    }
  }
`;

export const UPDATE_CLIENT_DETAILS = gql`
  mutation UpdateClientDetails(
    $accountId: ID!
    $preparerLinkId: ID!
    $clientDetails: TaxPayerDetailsInput!
  ) {
    updateClientDetails(
      accountId: $accountId
      preparerLinkId: $preparerLinkId
      clientDetails: $clientDetails
    ) {
      id
    }
  }
`;

export const CREATE_PREPARER_LINK = gql`
  mutation CreateTaxPreparerLink(
    $accountId: ID!
    $clientDetails: TaxPayerDetailsInput!
  ) {
    createPreparerLink(accountId: $accountId, clientDetails: $clientDetails) {
      id
    }
  }
`;

export const CREATE_TAX_PAYER_ACCOUNT_AS_PREPARER = gql`
  mutation CreateTaxPayerAccountAsPreparer(
    $name: String!
    $timezone: String
    $taxPayerDetails: TaxPayerDetailsInput
    $taxPreparerId: String
    $preparerAccountId: String
    $accountSubtype: String
  ) {
    createTaxPayerAccount(
      name: $name
      timezone: $timezone
      taxPayerDetails: $taxPayerDetails
      taxPreparerId: $taxPreparerId
      preparerAccountId: $preparerAccountId
      accountSubtype: $accountSubtype
    ) {
      id
      tmpUserToken
    }
  }
`;

export const UPDATE_PREPARER_BILLING = gql`
  mutation UpdatePreparerBilling($accountId: ID!, $preparerLinkId: ID!) {
    updatePreparerClientCharges(
      accountId: $accountId
      preparerLinkId: $preparerLinkId
    ) {
      id
      taxBilling {
        accountId
        baseTxAmount
        creditAvailable
        extraTransactions {
          year
          total
        }
        extraTransactionsAfterPay {
          year
          total
        }
        nextTxLimit {
          year
          total
        }
        currentTxLimit {
          year
          total
        }
        paymentRequired
        reportsPurchased
        taxYearReportAmount
        taxYearReportPurchased
        overagePrice {
          year
          price
          amount
          size
        }
        tierPrice {
          year
          price
          name
          lowerBound
          upperBound
        }
        history {
          tiers {
            year
            price
            name
            lowerBound
            upperBound
            timestamp
            whoPaid {
              payerType
              accountId
            }
          }
          overages {
            year
            price
            amount
            size
            timestamp
            whoPaid {
              payerType
              accountId
            }
          }
          reports {
            year
            price
            creditUsed
            timestamp
            whoPaid {
              payerType
              accountId
            }
          }
          discounts {
            year
            total
            coupon
            timestamp
            whoPaid {
              payerType
              accountId
            }
          }
          totals {
            year
            price
          }
          preparerTotals {
            year
            price
            accountId
          }
          clientTotals {
            year
            price
          }
        }
      }
      preparerCharges {
        year
        amount
      }
    }
  }
`;

export const ADD_PREPARER_TEAM_MEMBER = gql`
  mutation AddTaxPreparer(
    $email: String!
    $accountId: ID!
    $specificClientAccountIds: [String]
    $permission: String!
    $tirAdmin: Boolean
  ) {
    createTaxPreparer(
      email: $email
      accountId: $accountId
      specificClientAccountIds: $specificClientAccountIds
      permission: $permission
      tirAdmin: $tirAdmin
    ) {
      email
      type
      token
    }
  }
`;

export const EDIT_TEAM_MEMBER_PERMISSIONS = gql`
  mutation UpdateTaxPreparerPermission(
    $preparerId: ID!
    $accountId: ID!
    $clientAccounts: [String]!
    $permission: String!
  ) {
    updateTaxPreparerPermission(
      preparerId: $preparerId
      accountId: $accountId
      clientAccounts: $clientAccounts
      permission: $permission
    ) {
      userId
      accountId
      role
    }
  }
`;

export const REMOVE_TRANSFER_MATCH = gql`
  mutation RemoveTransferMatchLink(
    $accountId: ID!
    $taxTxId: String!
    $disableAutoMatching: Boolean!
  ) {
    removeTransferMatchLink(
      accountId: $accountId
      taxTxId: $taxTxId
      disableAutoMatching: $disableAutoMatching
    ) {
      id
      transactionId
      matchRef {
        amount
        sourceId
        sourceName
        sourceType
        timestamp
        transactionId
      }
      ignore
      transferMatchingDisabled
      transferMatchManual
    }
  }
`;

export const ENABLE_TRANSFER_MATCH = gql`
  mutation EnableTransferMatchLinks($accountId: ID!, $taxTxId: String!) {
    enableTransferMatchLinks(accountId: $accountId, taxTxId: $taxTxId) {
      id
      transactionId
      matchRef {
        amount
        sourceId
        sourceName
        sourceType
        timestamp
        transactionId
      }
      transferMatchingDisabled
      transferMatchManual
    }
  }
`;

export const CREATE_TRANSFER_MATCH = gql`
  mutation CreateTransferMatchLinks(
    $accountId: ID!
    $originalTaxTxId: String!
    $matchingTaxTxId: String!
  ) {
    createTransferMatch(
      accountId: $accountId
      originalTaxTxId: $originalTaxTxId
      matchingTaxTxId: $matchingTaxTxId
    ) {
      id
      transactionId
      matchRef {
        transactionId
      }
      transferMatchingDisabled
      transferMatchManual
    }
  }
`;

export const UPDATE_USER_OAUTH_CLIENT = gql`
  mutation UpdateUserOAuthClient($clientId: String) {
    updateUserOAuthClient(clientId: $clientId) {
      id
    }
  }
`;

export const VALIDATE_XPUBS = gql`
  mutation ValidateXpubs($accountId: ID!, $keys: [String]!, $chain: String) {
    validateXpubs(accountId: $accountId, keys: $keys, chain: $chain) {
      key
      valid
      duplicate
    }
  }
`;

export const DELETE_FAILED_MANUAL_EXCHANGE_ORDER = gql`
  mutation DeleteFailedManualExchangeOrder(
    $accountId: ID!
    $eSourceId: ID!
    $fileId: String!
  ) {
    deleteFailedManualExchangeOrder(
      accountId: $accountId
      eSourceId: $eSourceId
      fileId: $fileId
    )
  }
`;

export const DELETE_FAILED_MANUAL_WALLET_TRANSACTION = gql`
  mutation DeleteFailedManualWalletTransaction(
    $accountId: ID!
    $walletId: ID!
    $fileId: String!
  ) {
    deleteFailedManualWalletTransaction(
      accountId: $accountId
      walletId: $walletId
      fileId: $fileId
    )
  }
`;

export const UPDATE_TIR_CONFIG = gql`
  mutation UpdateTirConfig($accountId: ID!, $tirConfig: TirConfigInput!) {
    updateTirConfig(accountId: $accountId, tirConfig: $tirConfig) {
      id
      tirConfig {
        reportGenerating
      }
    }
  }
`;

export const ADD_TIR_CORRECTION_FILE = gql`
  mutation AddTirCorrectionFile($accountId: ID!, $filename: String!) {
    addTirCorrectionFile(accountId: $accountId, filename: $filename) {
      accountId
      filename
    }
  }
`;

export const UPDATE_EXCHANGE_BALANCE_MATERIALITY_THRESHOLD = gql`
  mutation UpdateExchangeBalanceMaterialityThresholds(
    $accountId: ID!
    $eSourceId: ID!
    $thresholds: BalancesReconciliationThresholdsInput
  ) {
    updateExchangeBalancesReconciliationThresholds(
      accountId: $accountId
      eSourceId: $eSourceId
      thresholds: $thresholds
    ) {
      id
      balancesReconciliation {
        materialityThreshold
        assets {
          asset
          materialityThreshold
        }
      }
    }
  }
`;

export const UPDATE_WALLET_BALANCE_MATERIALITY_THRESHOLD = gql`
  mutation UpdateWalletBalanceMaterialityThresholds(
    $accountId: ID!
    $walletId: ID!
    $thresholds: BalancesReconciliationThresholdsInput
  ) {
    updateWalletBalancesReconciliationThresholds(
      accountId: $accountId
      walletId: $walletId
      thresholds: $thresholds
    ) {
      id
      balancesReconciliation {
        materialityThreshold
        assets {
          asset
          contract
          materialityThreshold
        }
      }
    }
  }
`;

export const CREATE_SPEC_ID = gql`
  mutation CreateSpecId(
    $accountId: ID!
    $transactionDetails: [CreateSpecIdInput]!
  ) {
    createSpecId(
      accountId: $accountId
      transactionDetails: $transactionDetails
    ) {
      id
      costBasis
      isEdited
      priceAtTimeOfTx
      amount
      costBasisDisregardingFees
      proceedsDisregardingFees
      costBasisAssignments {
        lotIds
        lotNo
      }
      specIdTransactions {
        assignmentPercentage
        assignmentQuantity
        assignmentValue
        priceAtTimeOfTx
        sourceBase
        sourceId
        sourceName
        sourceType
        specId
        timestamp
        transactionId
        txHash
        txSide
      }
      reconciliations {
        isEdited
        isSpecId
      }
    }
  }
`;

export const REMOVE_SPEC_ID = gql`
  mutation RemoveSpecId($accountId: ID!, $specIds: [ID]!) {
    removeSpecId(accountId: $accountId, specIds: $specIds) {
      id
      costBasis
      isEdited
      priceAtTimeOfTx
      amount
      costBasisDisregardingFees
      proceedsDisregardingFees
      costBasisAssignments {
        lotIds
        lotNo
      }
      specIdTransactions {
        assignmentPercentage
        assignmentQuantity
        assignmentValue
        priceAtTimeOfTx
        sourceBase
        sourceId
        sourceName
        sourceType
        specId
        timestamp
        transactionId
        txHash
        txSide
      }
      reconciliations {
        isEdited
        isSpecId
      }
    }
  }
`;

export const TOGGLE_SPEC_ID_FEATURE = gql`
  mutation ToggleSpecIdFeature($accountId: ID!, $enable: Boolean!) {
    toggleSpecIdFeature(accountId: $accountId, enable: $enable) {
      id
      taxUpdater {
        taxUpdateInProgress
        taxUpdateNeedsRerun
        specIdFeature
      }
    }
  }
`;

export const DISABLE_MFA = gql`
  mutation DisableMFA($accountId: ID!) {
    disableMFA(accountId: $accountId) {
      id
      profile {
        email
        user_metadata {
          use_mfa
        }
      }
    }
  }
`;

export const ENABLE_MFA = gql`
  mutation EnableMFA($accountId: ID!, $code: String!, $otpCode: String!) {
    enableMFA(accountId: $accountId, code: $code, otpCode: $otpCode) {
      id
      profile {
        email
        user_metadata {
          use_mfa
        }
      }
    }
  }
`
